import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-requirements-monoclonal@2x.png"

import { theme } from "../../../styles/theme"

const MonoclonalIndex = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - monoclonal therapies" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            Monoclonal therapies
          </h3>
          <ul>
            <li>
              Treatments which include monoclonal antibody-based therapies are
              being used in the treatment of patients with haematological
              malignancies.
            </li>
            <li>
              NICE supports the use of the monoclonal anti-CD38 therapy,
              daratumumab (Darzalex) in patients with relapsed or refractory
              multiple myeloma (MM).
            </li>
            <li>
              Other monoclonal therapies currently at clinical trial stage
              include isatuximab, another anti-CD38 therapy for the treatment of
              relapsed or refractory MM patients, and an anti-CD47 (Hu5F9-G4),
              for use in patients with relapsed or refractory acute myeloid
              leukaemia (AML) or myelodysplastic syndrome (MDS). (CAMELLIA
              Trial).
            </li>
          </ul>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Considerations for provision of red cells
          </h4>
          <ul>
            <li>
              These therapies have the potential to adversely interfere with
              serological investigations and compatibility testing in the blood
              bank, potentially causing delays in providing blood components for
              transfusion.
            </li>
            <li>
              Different monoclonal antibody therapies may affect serological
              testing methods in a variety of ways, and the monoclonal antibody
              induced reactivity can persist for up to 6 months after the last
              treatment infusion.
            </li>
            <li>
              Discuss each case with your local transfusion laboratory, prior to monoclonal therapy commencing, to ensure
              provision can be met.
            </li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default MonoclonalIndex
